import Vue, { DirectiveOptions, markRaw } from 'vue'

import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'
import 'element-ui/lib/theme-chalk/display.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import App from '@/App.vue'
import Router from 'vue-router'
import router from '@/router'
import i18n from '@/lang'
import '@/icons/components'
import '@/permission'
import '@/pwa/register-service-worker'
import * as directives from '@/directives'
import * as filters from '@/filters'

import VueMoment from 'vue-moment'
import moment from 'moment'
require('moment/locale/fr')

import vueDebounce from 'vue-debounce'

import Trend from 'vuetrend'
import InfiniteLoading from 'vue-infinite-loading'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import { createPinia, PiniaVuePlugin } from 'pinia'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    release: `giftcard-market@${process.env.npm_package_version}`,
    dsn: 'https://a324770854af4449b82b23be2faf7894@o137618.ingest.sentry.io/5732476',
    tracesSampleRate: 0.8,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["api.staging.walleo.io", "api.walleo.io"],
      })
    ],
    trackComponents: true,
    environment: process.env.VUE_APP_ENV,
    attachProps: true,
    beforeSend(event) {
      const ignore_patterns = [/Request failed with status/i, /this\.src is null/i]
      if (event && event.message) {
        const ignored = ignore_patterns.find(pat => event?.message?.match(pat))
        return ignored ? null : event
      } else {
        return event
      }
    }
  })
}

Vue.use(vueDebounce, {
  listenTo: 'input'
})

Vue.use(ElementUI, {
  size: 'medium',
  i18n: (key: string, value: string) => i18n.t(key, value)
})

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

Vue.use(Trend)

Vue.use(LottieVuePlayer)

// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string ]: DirectiveOptions })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string ]: any })[key])
})

// Vue Moment
Vue.use(VueMoment, { moment })

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'waveDots',
    distance: 200
  }
})

Vue.config.productionTip = false

Vue.filter('capitalize', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

//Pinia
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

Vue.use(Router) // Router plugin MUST be initialized AFTER Pinia (so pinia can be instancied)

new Vue({
  router,
  i18n,
  pinia,
  render: (h) => h(App)
}).$mount('#app')
